/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable react/no-danger */
/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import {
  useQuery, useMutation, useQueryClient,
} from 'react-query';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Link, Element,
} from 'react-scroll';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Button,
} from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Popper from '@mui/material/Popper';
import type { AxiosResponse } from 'axios';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PopupState, { bindToggle, bindPopper } from 'material-ui-popup-state';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { useSnackbar } from 'notistack';
import StradaLoader from 'shared-components/components/StradaLoader';
import type { IviewLease } from '../types';

function Content(): JSX.Element {
  const { id } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState<boolean>(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { data, isLoading } = useQuery(
    ['get/lease-view', id],
    async () => axios({ url: `api/lease/${id}`, method: 'get' }),
    {
      select: (res: AxiosResponse<IviewLease>) => res.data.detail,
    },
  );
  const { mutate: deleteLease } = useMutation(
    // eslint-disable-next-line @typescript-eslint/no-shadow
    async (id: number | string) => axios({
      url: `/api/lease/${id}/`,
      method: 'delete',
    }),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries('get/lease').then();
        let message = '';
        if (data?.data.tenant_name != null) {
          message = `Lease abstraction "${data.data.tenant_name}" was deleted`;
        } else {
          message = 'Lease abstraction  was deleted';
        }
        enqueueSnackbar(message);
        window.history.back();
      },
    },
  );

  function normalizeHtmlContent(htmlContent: string): string {
    // return htmlContent;
    const container = document.createElement('div');
    container.innerHTML = htmlContent;
    const boldTags = container.querySelectorAll('b');

    boldTags.forEach((boldTag) => {
      const { nextSibling } = boldTag;
      if (nextSibling && nextSibling.nodeName === 'B') {
        const br = document.createElement('br');
        boldTag.parentNode?.insertBefore(br, nextSibling);
      }
    });

    return container.innerHTML;
  }

  return (

    <div className='main-lease-wrapper'>
      { isLoading ? <StradaLoader open={isLoading} />
        : (
          <div className='lease-view container'>

            {/*  section */}
            <Grid container>
              <Grid item md={9} display='flex' justifyContent='space-between' mt={2.4}>
                <div className='d-flex'>
                  <ArrowBackIcon className='back-icon' onClick={(): void => { window.history.back(); }} />
                  <p className='lease-heading'>{data?.data.tenant_name}</p>
                </div>
                <div>
                  <div
                    style={{
                      textAlign: 'right',
                      marginRight: '1rem',
                    }}
                  >
                    <PopupState variant='popper' popupId='demo-popup-popper'>
                      {(popupState): JSX.Element => (
                        <div>
                          <IconButton {...bindToggle(popupState)}>
                            <MoreHorizIcon />
                          </IconButton>
                          <Popper {...bindPopper(popupState)} transition>
                            {({ TransitionProps }): JSX.Element => (
                              <ClickAwayListener
                                onClickAway={(): void => {
                                  popupState.close();
                                }}
                              >
                                <Fade {...TransitionProps} timeout={350}>
                                  <Paper className='checklist-list-popover'>
                                    <div
                                      className='chart-btn'
                                      onClick={(): void => {
                                        navigate('/workspace/lease/upload/edit', { state: data?.data });
                                        popupState.close();
                                      }}
                                      aria-hidden='true'
                                    >
                                      <EditIcon className='edit-delete-icon' />
                                      <span className='edit-delete-text '>
                                        Edit
                                      </span>
                                    </div>
                                    <div
                                      className='chart-btn'
                                      onClick={(): void => {
                                        setOpen(true);
                                        popupState.close();
                                      }}
                                      aria-hidden='true'
                                    >
                                      <DeleteIcon className='edit-delete-icon' />
                                      <span className='edit-delete-text '>
                                        Delete
                                      </span>
                                    </div>

                                  </Paper>
                                </Fade>
                              </ClickAwayListener>
                            )}
                          </Popper>
                        </div>
                      )}
                    </PopupState>
                  </div>
                </div>
              </Grid>
            </Grid>

            {data?.data.abstractions.length !== 0 && data?.headings.length !== 0

              ? (
                <Grid container justifyContent='center' justifySelf='center'>
                  <Grid item md={12} display='flex' direction='row-reverse'>
                    <div className='heading-table'>
                      <div className='heading-inner-table-box'>

                        { data?.headings.map((heading, idx) => (
                          <Link
                            activeClass='active'
                            to={heading}
                            smooth
                            duration={80}
                            offset={idx === 0 ? -150 : -80}
                            isDynamic
                            delay={0}
                            spy
                            className='lease-link'
                            // eslint-disable-next-line react/no-array-index-key
                            key={idx}
                          >
                            {heading}
                          </Link>
                        ))}

                      </div>

                    </div>
                    <div className='right-wrapper'>

                      {data?.data.abstractions.map((abs) => (
                        <Element name={abs.title}>
                          <p className='lease-sub-heading mt-3 mb-1'>
                            <a
                              target='_blank'
                              href={`${process.env.REACT_APP_BASE_URL}${abs.pdf_url}`}
                              rel='noreferrer'
                              style={{ textDecoration: 'none', color: '#00CFA1' }}
                            >
                              {abs.title}
                            </a>
                          </p>
                          <div className='desc' dangerouslySetInnerHTML={{ __html: normalizeHtmlContent(abs.description) }} />
                        </Element>
                      ))}
                      {/* <button onClick={(): void => { id != null ? deleteLease(id) : ''; }} type='button' className='delete-lease-btn'>Delete Lease Abstraction</button> */}
                    </div>
                  </Grid>

                </Grid>
              )
              : (
                <Grid container justifyContent='center' justifySelf='center'>
                  <Grid item md={12} display='flex'>
                    <div className='empty-lease-wrapper'>
                      <p>No Lease Data Found</p>
                    </div>
                  </Grid>
                </Grid>
              )}
          </div>
        )}

      <Dialog
        open={open}
        keepMounted
        aria-labelledby='alert-dialog-slide-title'
        aria-describedby='alert-dialog-slide-description'
        PaperProps={{
          elevation: 0,
          style: {
            width: '600px',
            paddingBottom: '.5rem',
            paddingRight: '.7rem',
          },
        }}
      >
        <DialogTitle>
          <div>
            <p style={{ fontSize: '20px', color: 'rgba(33, 33, 33, 0.87)' }}>
              Delete lease abstraction &#34;
              {data?.data.tenant_name}
              &#34;?
            </p>
          </div>
        </DialogTitle>
        <DialogContent>
          This can&apos;t be undone.
        </DialogContent>
        <DialogActions>
          <Button
            className='text-transform-none'
            style={{ color: '#00CFA1', lineHeight: '1.7rem' }}
            onClick={(): void => { setOpen(false); }}
          >
            Cancel
          </Button>
          <Button
            type='submit'
            variant='contained'
            className='ms-2 text-transform-none'
            style={{ color: 'white', padding: '6px 8px' }}
            onClick={(): void => { deleteLease(String(data?.data.id)); }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

    </div>

  );
}

export default Content;
