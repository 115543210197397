/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import { useMutation, useQuery } from 'react-query';
import {
  Avatar, IconButton, TextField, Tooltip, Badge, Drawer, Popover, Button,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Popper from '@mui/material/Popper';
import PopupState, { bindToggle, bindPopper } from 'material-ui-popup-state';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import type { AxiosResponse } from 'axios';
import CloseIcon from '@mui/icons-material/Close';
import InsertCommentIcon from '@mui/icons-material/InsertComment';
import { useParams, useNavigate } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import moment from 'moment';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { styled } from '@mui/system';

import PrimayButton from 'shared-components/components/PrimayButton';
import CustomLoader from 'shared-components/components/CustomLoader';

interface IComment {
  comment: string;
  created?: string;
  id?: number;
  image: number;
  index?: number;
  modified?: string;
  user_image?: string;
  humanize_time?: string;
  user_name?: string;
  x_axis: number | null;
  y_axis: number | null;
}

interface ISingleImage {
  comments: IComment[];
  file: string;
  file_name: string;
  id: number;
  total_comments: number;
  is_dynamic_comments: boolean;
  created: string;
}
interface ISingleImage {
  comments: IComment[];
  file: string;
  file_name: string;
  id: number;
  total_comments: number;
}
interface IResponse {
  detail: ISingleImage[];
}
interface IImgResponse {
  detail: ISingleImage;
}
interface ClickPosition {
  x: number;
  y: number;
}

const CustomTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    borderRadius: 8,
    width: '264px',
    height: '64px',
    backgroundColor: 'transparent',
    color: 'white',
    padding: '8px',
    '& fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.24)',
    },
    '&:hover fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.24)',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#00CFA1',
    },
    '& input::placeholder': {
      fontWeight: 400,
      fontSize: '16px',
      color: 'rgba(255, 255, 255, 0.38)',
    },
  },
});
const theme = createTheme({
  palette: {
    success: {
      main: '#00CFA1',
    },
  },
  components: {
    MuiBadge: {
      styleOverrides: {
        badge: {
          color: 'white',
          fontSize: '10px',
        },
      },
    },
  },
});
const theme2 = createTheme({
  components: {
    MuiBadge: {
      styleOverrides: {
        badge: {
          color: 'white',
          fontSize: '10px',
          top: '20px',
          border: '1px solid white',
        },
      },
    },
  },
});
const theme3 = createTheme({
  components: {
    MuiBadge: {
      styleOverrides: {
        badge: {
          color: 'white',
          fontSize: '10px',
          border: '1px solid white',
        },
      },
    },
  },
});

export default function ImageComment(): JSX.Element {
  const { checklistId, imageId } = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [allImages, setAllImages] = useState<ISingleImage[]>([]);
  const [singleImage, setSingleImage] = useState<ISingleImage | undefined>();
  const [imgIndex, setImgIndex] = useState<number>(-1);
  const [imgId, setImgId] = useState<number>(-1);
  const [commentId, setCommentId] = useState<number | undefined>();
  const [commentValue, setCommentValue] = useState<string>('');
  const [popupCommentValue, setPopupCommentValue] = useState<string>('');
  const [editComment, setEditComment] = useState<IComment | null>(null);
  const [showComment, setShowComment] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [editAnchorEl, setEditAnchorEl] = useState<HTMLElement | null>(null);
  const [clickPositionPayload, setClickPositionPayload] = useState<ClickPosition>({ x: 0, y: 0 });
  const [clickPosition, setClickPosition] = useState<ClickPosition>({ x: 0, y: 0 });
  const [zoomLevel, setZoomLevel] = useState(1);

  useEffect(() => {
    if (imgIndex !== -1) {
      navigate(`/workspace/view-checklist-image/${checklistId}/${allImages[imgIndex].id}`);
      setSingleImage(allImages[imgIndex]);
      setImgId(allImages[imgIndex].id);
    } else {
      setImgId(Number(imageId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imgIndex]);

  const { isLoading } = useQuery(
    'get-task-images',
    async () => axios({
      url: `/api/checklist-form-image-comment/get-task-images/?image_id=${imgId === -1 ? imageId : imgId}`,
      method: 'get',
    }),
    {
      onSuccess: (res: AxiosResponse<IResponse>) => {
        setAllImages(res.data.detail);
        const index = res.data.detail.findIndex((obj) => obj.id === Number(imgId));
        const foundImg = res.data.detail.find((obj) => obj.id === Number(imgId));
        setSingleImage(foundImg);
        setImgIndex(index);
      },
    },
  );
  const { mutate: addComment } = useMutation(async (commentData: IComment) => axios({
    url: '/api/checklist-form-image-comment/',
    method: 'POST',
    data: commentData,
  }), {
    onSuccess: (res: AxiosResponse<IImgResponse>) => {
      setCommentValue('');
      setPopupCommentValue('');
      setClickPositionPayload({ x: 0, y: 0 });
      setSingleImage(res.data.detail);
      setAllImages((prevImages) => prevImages.map((item) => (item.id === res.data.detail.id ? res.data.detail : item)));
      enqueueSnackbar('Comment Added Successfully');
    },
    onError: (): void => {
      enqueueSnackbar('Failed to add comment', {
        variant: 'error',
        content: (key, message) => (
          <div className='text-white bg-danger ps-4 pe-5 py-3'>
            {message}
          </div>
        ),
      });
    },
  });
  const { mutate: updateComment } = useMutation(async (commentData: IComment) => axios({
    url: `/api/checklist-form-image-comment/${commentData.id}/`,
    method: 'patch',
    data: commentData,
  }), {
    onSuccess: (res: AxiosResponse<IImgResponse>) => {
      setCommentId(undefined);
      setCommentValue('');
      setPopupCommentValue('');
      setClickPositionPayload({ x: 0, y: 0 });
      setSingleImage(res.data.detail);
      setAllImages((prevImages) => prevImages.map((item) => (item.id === res.data.detail.id ? res.data.detail : item)));
      enqueueSnackbar('Comment updated successfully');
    },
    onError: (): void => {
      enqueueSnackbar('Failed to update comment', {
        variant: 'error',
        content: (key, message) => (
          <div className='text-white bg-danger ps-4 pe-5 py-3'>
            {message}
          </div>
        ),
      });
    },
  });
  const { mutate: deleteComment } = useMutation(async (deleteId: number | string | undefined) => axios({
    url: `/api/checklist-form-image-comment/${deleteId}/`,
    method: 'DELETE',
  }), {
    onSuccess: (res: AxiosResponse<IImgResponse>) => {
      setSingleImage(res.data.detail);
      setAllImages((prevImages) => prevImages.map((item) => (item.id === res.data.detail.id ? res.data.detail : item)));
      enqueueSnackbar('Comment Deleted Successfully');
    },
    onError: (): void => {
      enqueueSnackbar('Failed to delete comment', {
        variant: 'error',
        content: (key, message) => (
          <div className='text-white bg-danger ps-4 pe-5 py-3'>
            {message}
          </div>
        ),
      });
    },
  });
  const handleComment = (): void => {
    if (commentId === undefined) {
      addComment({
        image: imgId, comment: commentValue === '' ? popupCommentValue : commentValue, x_axis: clickPositionPayload.x !== 0 ? clickPositionPayload.x : null, y_axis: clickPositionPayload.y !== 0 ? clickPositionPayload.y : null,
      });
    } else {
      updateComment({
        id: commentId, image: imgId, comment: commentValue === '' ? popupCommentValue : commentValue, x_axis: clickPositionPayload.x !== 0 ? clickPositionPayload.x : null, y_axis: clickPositionPayload.y !== 0 ? clickPositionPayload.y : null,
      });
    }
  };
  const handleZoomIn = (): void => {
    if (zoomLevel < 1.2) {
      setZoomLevel((prevZoomLevel) => prevZoomLevel + 0.1);
    }
  };

  const handleZoomOut = (): void => {
    if (zoomLevel > 0.2) {
      setZoomLevel((prevZoomLevel) => prevZoomLevel - 0.1);
    }
  };

  const handlePrevious = (): void => {
    setImgIndex((prevImage) => (prevImage - 1 + allImages.length) % allImages.length);
  };

  const handleNext = (): void => {
    setImgIndex((prevImage) => (prevImage + 1) % allImages.length);
  };
  const handleClick = (event: React.MouseEvent<HTMLImageElement>): void => {
    const target = event.currentTarget as HTMLElement;
    setAnchorEl(target);
    const rect = target.getBoundingClientRect();
    const x = (event.clientX - rect.left) / rect.width;
    const y = (event.clientY - rect.top) / rect.height;
    const normalizedX = x * 100;
    const normalizedY = y * 100;
    setClickPositionPayload({ x: normalizedX, y: normalizedY });
    setClickPosition({ x: event.clientX, y: event.clientY });
  };
  const handleBadgeClick = (event: React.MouseEvent<HTMLSpanElement>): void => {
    const target = event.currentTarget as HTMLSpanElement;
    setEditAnchorEl(target);
    setClickPosition({ x: event.clientX, y: event.clientY });
  };
  const handleClose = (): void => {
    setAnchorEl(null);
    setEditAnchorEl(null);
    setEditComment(null);
    setPopupCommentValue('');
    setClickPosition({ x: 0, y: 0 });
  };

  const open = Boolean(anchorEl);
  const openEdit = Boolean(editAnchorEl);
  const id = open ? 'popup' : undefined;

  return (
    <div className='image-comment-wrapper'>
      {isLoading ? <div className='d-flex justify-center align-items-center w-100' style={{ height: '90vh' }}><CustomLoader /></div>
        : (
          <>
            <div className='image-comment-header'>
              <div>
                <p className='file-name'>{singleImage !== undefined ? singleImage.file_name : ''}</p>
                <p className='file-date'>{singleImage !== undefined ? moment(singleImage.created).format('D MMM, YYYY [at] HH:mm') : ''}</p>
              </div>
              <div className='d-flex align-items-center'>
                <div
                  className='comment-div-wrapper'
                  style={{ background: showComment ? 'rgba(255, 255, 255, 0.04)' : '#2C2C2C' }}
                  aria-hidden='true'
                  onClick={(): void => { setShowComment(!showComment); }}
                >
                  <ThemeProvider theme={theme}>
                    <Badge color='success' badgeContent={singleImage !== undefined ? singleImage.total_comments : 0}>
                      <InsertCommentIcon />
                    </Badge>
                  </ThemeProvider>
                  <p>Comments</p>
                </div>
                <div
                  className='cursor-pointer'
                  aria-hidden='true'
                  onClick={(): void => {
                    navigate(`/workspace/view-checklist/${checklistId}`);
                  }}
                >
                  <CloseIcon />

                </div>
              </div>
            </div>
            <div className='image-comment-body' style={{ paddingRight: showComment ? '0px' : ' 24px' }}>
              <div className='d-flex align-items-center' style={{ width: '100%' }}>
                <IconButton style={{ color: 'black', display: allImages.length === 1 || imgIndex === 0 ? 'none' : 'block' }} onClick={handlePrevious} disabled={imgIndex === 0}>
                  <KeyboardArrowLeftIcon />
                </IconButton>
                <Carousel
                  selectedItem={imgIndex}
                  // onChange={setCurrentImage}
                  showThumbs={false}
                  showStatus={false}
                  renderArrowPrev={(): null => null}
                  renderArrowNext={(): null => null}
                  renderIndicator={(): null => null}
                >
                  {allImages.map((image, index) => (
                    <div key={image.id} aria-hidden='true' onClick={handleClick} style={{ transform: `scale(${zoomLevel})` }}>
                      <img
                        src={`${process.env.REACT_APP_IMAGE_URL}${image.file}`}
                        alt={`comment ${index + 1}`}
                        style={{
                          maxHeight: '85vh',
                          height: '80vh',
                          maxWidth: '80%',
                          width: '80%',
                          // transform: `scale(${zoomLevel})`,
                          objectFit: image.is_dynamic_comments ? 'unset' : 'contain',
                          cursor: 'pointer',
                        }}
                      />
                      {image.comments.map((comment) => (
                        <div>
                          {comment.x_axis !== null && (
                            <ThemeProvider theme={theme3}>
                              <Badge
                                badgeContent={comment.index === -1 ? '' : comment.index}
                                color='primary'
                                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                                style={{
                                  position: 'absolute',
                                  top: `${comment.y_axis}%`,
                                  left: `${comment.x_axis}%`,
                                  transform: 'translate(-50%, -50%)',
                                  zIndex: 1,
                                }}
                                onClick={(e): void => { setEditComment(comment); e.stopPropagation(); handleBadgeClick(e); }}
                              />
                            </ThemeProvider>
                          )}
                        </div>
                      ))}
                    </div>
                  ))}
                </Carousel>
                <IconButton style={{ color: 'black', display: allImages.length === 1 || imgIndex === allImages.length - 1 ? 'none' : 'block' }} onClick={handleNext} disabled={imgIndex === allImages.length - 1}>
                  <KeyboardArrowRightIcon />
                </IconButton>
              </div>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorReference='anchorPosition'
                anchorPosition={{
                  top: clickPosition.y,
                  left: clickPosition.x + 10,
                }}
              >
                <div>
                  <div className='popup-comment-div p-3'>
                    <CustomTextField
                      variant='standard'
                      InputProps={{
                        disableUnderline: true,
                      }}
                      placeholder='Add comment...'
                      value={popupCommentValue}
                      multiline
                      rows={2}
                      onChange={(e): void => { setPopupCommentValue(e.target.value); }}
                    />
                    <Button
                      disabled={popupCommentValue === ''}
                      variant='contained'
                      style={{
                        textTransform: 'inherit',
                        marginTop: '24px',
                        width: 'fit-content',
                        display: 'block',
                        marginLeft: 'auto',
                        color: popupCommentValue === '' ? '212121' : 'white',
                        background: popupCommentValue === '' ? 'E4E4E4' : '#00CFA1',
                        whiteSpace: 'nowrap',
                      }}
                      onClick={(): void => { handleComment(); handleClose(); }}
                    >
                      Send
                    </Button>

                  </div>
                </div>
              </Popover>
              <Popover
                id={id}
                open={openEdit}
                anchorEl={editAnchorEl}
                onClose={handleClose}
                anchorReference='anchorPosition'
                anchorPosition={{
                  top: clickPosition.y,
                  left: clickPosition.x + 10,
                }}
              >
                <div>
                  {editComment !== null
                    && (
                      <div className='popup-comment-div p-3'>
                        <div className='d-flex'>
                          <Avatar
                            sx={{ height: '32px', width: '32px' }}
                            src={`${process.env.REACT_APP_IMAGE_URL}${editComment.user_image}`}
                          />
                          <div className='ms-4'>
                            <div className='d-flex align-items-center'>
                              <p className='user-name'>{editComment.user_name !== '' ? editComment.user_name : 'No User'}</p>
                            </div>
                            <p className='comment-text'>{editComment.comment}</p>
                          </div>

                        </div>
                        <div>
                          <PopupState variant='popper' popupId='demo-popup-popper3'>
                            {(popupState): JSX.Element => (
                              <div>
                                <IconButton {...bindToggle(popupState)}>
                                  <MoreHorizIcon />
                                </IconButton>
                                <Popper {...bindPopper(popupState)} style={{ zIndex: '112244444' }} transition>
                                  {({ TransitionProps }): JSX.Element => (
                                    <ClickAwayListener
                                      onClickAway={(): void => {
                                        setPopupCommentValue('');
                                        popupState.close();
                                      }}
                                    >
                                      <Fade {...TransitionProps} timeout={350}>
                                        <Paper className='checklist-list-popover'>
                                          <div
                                            className='chart-btn'
                                            aria-hidden='true'
                                            onClick={(): void => { setPopupCommentValue(editComment.comment); popupState.close(); setCommentId(editComment.id); setClickPositionPayload({ x: editComment.x_axis !== null ? editComment.x_axis : 0, y: editComment.y_axis !== null ? editComment.y_axis : 0 }); setAnchorEl(editAnchorEl); setEditAnchorEl(null); }}
                                          >
                                            <span className='edit-delete-text ms-0'> Edit</span>
                                          </div>
                                          <div
                                            className='chart-btn'
                                            onClick={(): void => {
                                              deleteComment(editComment.id);
                                              popupState.close();
                                              handleClose();
                                            }}
                                            aria-hidden='true'
                                          >
                                            <span className='edit-delete-text ms-0'>
                                              Delete
                                            </span>
                                          </div>
                                        </Paper>
                                      </Fade>
                                    </ClickAwayListener>
                                  )}
                                </Popper>
                              </div>
                            )}
                          </PopupState>
                        </div>
                      </div>
                    )}
                </div>
              </Popover>
              {showComment
                && (
                  <div>
                    {window.innerWidth < 600
                      ? (
                        <Drawer
                          anchor='right'
                          open
                          PaperProps={{ elevation: 0, style: { backgroundColor: '#2C2C2C', marginTop: '64px' } }}
                        >
                          <div className='add-comment-drawer-div'>
                            <div className='d-flex align-items-center justify-space-between mb-4'>
                              <p>Comment</p>
                              <div aria-hidden='true' onClick={(): void => { setShowComment(false); }}>
                                <KeyboardArrowRightIcon />
                              </div>
                            </div>
                            <CustomTextField
                              variant='outlined'
                              placeholder='Write a comment...'
                              value={commentValue}
                              multiline
                              rows={2}
                              onChange={(e): void => { setCommentValue(e.target.value); }}
                            />
                            {commentValue !== ''
                              && (
                                <PrimayButton style={{ marginTop: '24px', width: 'fit-content', display: 'block' }} onClick={(): void => { handleComment(); }}>
                                  Add comment
                                </PrimayButton>
                              )}
                            {singleImage !== undefined && singleImage.comments.length > 0 && singleImage.comments.map((comment) => (
                              <div className='single-comment-wrap mt-4'>
                                <div className='d-flex'>
                                  <ThemeProvider theme={theme2}>
                                    {comment.index === -1
                                      ? (
                                        <Avatar
                                          sx={{ height: '32px', width: '32px' }}
                                          src={`${process.env.REACT_APP_IMAGE_URL}${comment.user_image}`}
                                        />
                                      )
                                      : (
                                        <Badge
                                          badgeContent={comment.index}
                                          color='primary'
                                        >
                                          <Avatar
                                            sx={{ height: '32px', width: '32px' }}
                                            src={`${process.env.REACT_APP_IMAGE_URL}${comment.user_image}`}
                                          />
                                        </Badge>
                                      )}
                                  </ThemeProvider>
                                  <div className='ms-4'>
                                    <div className='d-flex align-items-center'>
                                      <p className='user-name'>{comment.user_name !== '' ? comment.user_name : 'No User'}</p>
                                      <Tooltip title={moment(comment.created).format('D MMM, YYYY [at] HH:mm')}>
                                        <p className='comment-time'>
                                          {' '}
                                          {comment.humanize_time}
                                        </p>
                                      </Tooltip>
                                    </div>
                                    <p className='comment-text'>{comment.comment}</p>
                                  </div>
                                </div>
                                <div>
                                  <PopupState variant='popper' popupId='demo-popup-popper2'>
                                    {(popupState): JSX.Element => (
                                      <div>
                                        <IconButton {...bindToggle(popupState)}>
                                          <MoreHorizIcon />
                                        </IconButton>
                                        <Popper {...bindPopper(popupState)} style={{ zIndex: '112244444' }} transition>
                                          {({ TransitionProps }): JSX.Element => (
                                            <ClickAwayListener
                                              onClickAway={(): void => {
                                                popupState.close();
                                              }}
                                            >
                                              <Fade {...TransitionProps} timeout={350}>
                                                <Paper className='checklist-list-popover'>
                                                  <div
                                                    className='chart-btn'
                                                    aria-hidden='true'
                                                    onClick={(): void => { setCommentValue(comment.comment); setCommentId(comment.id); setClickPositionPayload({ x: comment.x_axis !== null ? comment.x_axis : 0, y: comment.y_axis !== null ? comment.y_axis : 0 }); popupState.close(); }}
                                                  >
                                                    <span className='edit-delete-text ms-0'> Edit</span>
                                                  </div>
                                                  <div
                                                    className='chart-btn'
                                                    onClick={(): void => {
                                                      deleteComment(comment.id);
                                                      popupState.close();
                                                    }}
                                                    aria-hidden='true'
                                                  >
                                                    <span className='edit-delete-text ms-0'>
                                                      Delete
                                                    </span>
                                                  </div>
                                                </Paper>
                                              </Fade>
                                            </ClickAwayListener>
                                          )}
                                        </Popper>
                                      </div>
                                    )}
                                  </PopupState>
                                </div>
                              </div>
                            ))}
                          </div>
                        </Drawer>
                      )
                      : (
                        <div className='add-comment-div'>
                          <div className='d-flex align-items-center justify-space-between mb-4'>
                            <p>Comment</p>
                            <div aria-hidden='true' onClick={(): void => { setShowComment(false); }}>
                              <KeyboardArrowRightIcon />
                            </div>
                          </div>
                          <CustomTextField
                            variant='outlined'
                            placeholder='Write a comment...'
                            value={commentValue}
                            multiline
                            rows={2}
                            onChange={(e): void => { setCommentValue(e.target.value); }}
                          />
                          {commentValue !== ''
                            && (
                              <PrimayButton style={{ marginTop: '24px', width: 'fit-content' }} onClick={(): void => { handleComment(); }}>
                                Add comment
                              </PrimayButton>
                            )}
                          {singleImage !== undefined && singleImage.comments.length > 0 && singleImage.comments.map((comment) => (
                            <div className='single-comment-wrap mt-4'>
                              <div className='d-flex'>
                                <ThemeProvider theme={theme2}>
                                  {comment.index === -1
                                    ? (
                                      <Avatar
                                        sx={{ height: '32px', width: '32px' }}
                                        src={`${process.env.REACT_APP_IMAGE_URL}${comment.user_image}`}
                                      />
                                    )
                                    : (
                                      <Badge
                                        badgeContent={comment.index}
                                        color='primary'
                                      >
                                        <Avatar
                                          sx={{ height: '32px', width: '32px' }}
                                          src={`${process.env.REACT_APP_IMAGE_URL}${comment.user_image}`}
                                        />
                                      </Badge>
                                    )}
                                </ThemeProvider>
                                <div className='ms-4'>
                                  <div className='d-flex align-items-center'>
                                    <p className='user-name'>{comment.user_name !== '' ? comment.user_name : 'No User'}</p>
                                    <Tooltip title={moment(comment.created).format('D MMM, YYYY [at] HH:mm')}>
                                      <p className='comment-time'>
                                        {' '}
                                        {comment.humanize_time}
                                      </p>
                                    </Tooltip>
                                  </div>
                                  <p className='comment-text'>{comment.comment}</p>
                                </div>
                              </div>
                              <div>
                                <PopupState variant='popper' popupId='demo-popup-popper2'>
                                  {(popupState): JSX.Element => (
                                    <div>
                                      <IconButton {...bindToggle(popupState)}>
                                        <MoreHorizIcon />
                                      </IconButton>
                                      <Popper {...bindPopper(popupState)} style={{ zIndex: '1122' }} transition>
                                        {({ TransitionProps }): JSX.Element => (
                                          <ClickAwayListener
                                            onClickAway={(): void => {
                                              popupState.close();
                                            }}
                                          >
                                            <Fade {...TransitionProps} timeout={350}>
                                              <Paper className='checklist-list-popover'>
                                                <div
                                                  className='chart-btn'
                                                  aria-hidden='true'
                                                  onClick={(): void => { setCommentValue(comment.comment); setCommentId(comment.id); setClickPositionPayload({ x: comment.x_axis !== null ? comment.x_axis : 0, y: comment.y_axis !== null ? comment.y_axis : 0 }); popupState.close(); }}
                                                >
                                                  <span className='edit-delete-text ms-0'> Edit</span>
                                                </div>
                                                <div
                                                  className='chart-btn'
                                                  onClick={(): void => {
                                                    deleteComment(comment.id);
                                                    popupState.close();
                                                  }}
                                                  aria-hidden='true'
                                                >
                                                  <span className='edit-delete-text ms-0'>
                                                    Delete
                                                  </span>
                                                </div>
                                              </Paper>
                                            </Fade>
                                          </ClickAwayListener>
                                        )}
                                      </Popper>
                                    </div>
                                  )}
                                </PopupState>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                  </div>
                )}
              <div className='zoom-controls'>
                <div
                  className='comment-icon-wrapper'
                  aria-hidden='true'
                  onClick={(e): void => { e.stopPropagation(); setShowComment(!showComment); }}
                >
                  <InsertCommentIcon />
                  <p>Comments</p>
                </div>
                <span className='zoom-icon' aria-hidden='true' onClick={(e): void => { e.stopPropagation(); handleZoomOut(); }}>-</span>
                <span className='zoom-icon' aria-hidden='true' onClick={(e): void => { e.stopPropagation(); handleZoomIn(); }}>+</span>

              </div>
            </div>

          </>
        )}
    </div>
  );
}
